import request2 from '@/utils/request2'
import { SYSTEM_URL } from '@/api/baseUrl'

/**
 * 获取数据
 * @param {*} params
 */
export function get(params) {
  return request2({
    url: SYSTEM_URL() + '/sysdicttype/dictData',
    method: 'get'
  })
}
