<template>
  <div style="height:100%;">
    <transition name="fade-transform" mode="out-in">
      <!-- <keep-alive> -->
      <router-view class="router-view" />
      <!-- </keep-alive> -->
    </transition>
  </div>
</template>
<script>

export default {
  name: 'LayoutDefault'
}
</script>
