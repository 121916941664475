import store from '../store'

const dict = {

  /**
 * 获取字典label
 * @param key
 * @param val
 * @returns {string}
 */
  getDictLabel: function(key, val) {
    const data = dict.getDictList(key)
    if (data && data.length >= 0) {
      const vals = data.filter(item => item.val === val + '')
      if (vals && vals.length > 0) {
        return vals[0].label
      } else {
        return ''
      }
    }
  },
  /**
 * 获取指定key的字典集合
 * key dict_type
 * valid true 有效项目
 */
  getDictList: function(key, valid) {
    const dictData = store.getters.dictData
    let keyData = {}
    if (dictData && dictData.length >= 0) {
      keyData = dictData.filter(item => item.key === key)[0]
    }
    if (keyData && keyData.val) {
      let arr = []
      // 使用方面获取有效数组
      if (valid) {
        arr = keyData.val.filter(item => item.status.toString() !== '0')
      } else {
        arr = keyData.val
      }
      return arr
    } else {
      return []
    }
  }
}
export default dict
