
/**
 * TOKEN  等Storage的处理
 * <pre>
 * 作者:hugh zhuang
 * 邮箱:3378340995@qq.com
 * 日期:2018-10-08-下午3:29:34
 * 版权:山东易企赢
 * </pre>
 */
import Storage from '@/utils/storage'
import { UUID_KEY, LANG_KEY, TOKEN_STORE_KEY, REFRESH_TOKEN_STORE_KEY, TOKEN_STORE, TOKEN_DOMAIN, TOKEN_STORE_PREFIX, TENANT_ID } from '@/constant'

// 是否需要前缀
const isPrefix = TOKEN_STORE_PREFIX !== 'custom'

function isEmpty(v) {
  return v === undefined || v === null || v === ''
}

// 截取Url里面的参数
export const GetRequest = () => {
  const url = decodeURIComponent(location.search) // 获取url中"?"符后的字串
  const theRequest = {}
  if (url.indexOf('?') !== -1) {
    const str = url.substr(1)
    // alert(str);
    const strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1])// 获取中文参数转码<span style="font-family: Arial, Helvetica, sans-serif;">decodeURI</span>，（unescape只针对数字，中文乱码)
    }
  }
  return theRequest
}
/**
 * 获取token
 */
export function getToken() {
  const val = Storage.get(TOKEN_STORE_KEY, '', isPrefix)
  return !isEmpty(val) ? val : undefined
}

/**
 * 设置token
 */
export function setToken(token, optins) {
  return Storage.set(TOKEN_STORE_KEY, token, optins, isPrefix)
}

/**
 * 删除token
 */
export function removeToken() {
  return Storage.remove(TOKEN_STORE_KEY, isPrefix)
}

// ===========刷新token==========
/**
 * 获取刷新token
 */
export function getRefreshToken() {
  return Storage.get(REFRESH_TOKEN_STORE_KEY, '', isPrefix)
}

/**
 * 设置刷新token
 */
export function setRefreshToken(token, optins) {
  return Storage.set(REFRESH_TOKEN_STORE_KEY, token, optins, isPrefix)
}

/**
 * 删除刷新token
 */
export function removeRefreshToken() {
  return Storage.remove(REFRESH_TOKEN_STORE_KEY, isPrefix)
}

/**
 * 删除租户信息
 */
export function removeTenantId() {
  return Storage.remove(TENANT_ID, isPrefix)
}

/**
 * 更新tonken（包含token和 refreshToken）
 */
export function updateToken(data) {
  console.log('updateToken')
  const domain = TOKEN_STORE === 'domain' ? TOKEN_DOMAIN : null
  // tonken 过期时间提前1分钟 前端不处理过期时间，后端处理过期时间
  // data.expires_in ? new Date(new Date().getTime() + ((parseInt(data.expires_in, 10) + 60) * 1000)) : 7
  setToken(data.access_token, {
    expires: null,
    domain: domain
  })
  // 刷新tonken过期时间提前2分钟
  setRefreshToken(data.refresh_token, {
    expires: null,
    domain: domain
  })
}

// UUID

export function getUuid() {
  return Storage.get(UUID_KEY, 'ghost-uuid', isPrefix)
}

export function setUuid(uuid, optins) {
  return Storage.set(UUID_KEY, uuid, optins, isPrefix)
}

export function removeUuid() {
  return Storage.remove(UUID_KEY, isPrefix)
}

// 语言

export function getLang() {
  return Storage.get(LANG_KEY, 'zh-CN', isPrefix)
}

export function setLang(value, optins) {
  return Storage.set(LANG_KEY, value, optins, isPrefix)
}

export function removeLang() {
  return Storage.remove(LANG_KEY, isPrefix)
}

