// 全局css样式
import 'normalize.css'
import 'vant/lib/index.less'
import 'vant/lib/icon/local.css'
import '@/assets/fonts/ibps-icon.scss'

import Vue from 'vue'
// 移动端调试
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

import App from './App'
import router from './router'
import store from './store'
import './errorLog' // error log 错误日志
import i18n from './i18n' // Internationalization 国际化
import utils from './utils/util' // utils 帮助类
import env from '@/env'
import ActionUtils from '@/utils/action'
import dict from '@/utils/dict'

import amap from '@/plugins/map/amap'
import vant from 'vant'
import { GetRequest } from '@/utils/auth'

Vue.use(vant) // 导入vant
Vue.use(amap)
Vue.prototype.$action = ActionUtils // 全局action
Vue.prototype.$utils = utils // 全局帮助类
// 基础路径
Vue.prototype.$baseUrl = process.env.VUE_APP_PUBLIC_PATH || '/'
// 当前环境
Vue.prototype.$nodeEnv = process.env.NODE_ENV
// 当前环境变量
Vue.prototype.$env = env
// 数据字典
Vue.prototype.$dict = dict

// http 工具
import $http from '@/utils/request'
Vue.prototype.$http = $http

// 移动端调试
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole()
// Vue.use(vConsole)

import * as filters from './filters' // global filters 全局过滤
// register global utility filters. 注册全局过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
/**
 *  日志输出开关
 */
Vue.config.productionTip = false
const layout = GetRequest().layout
if (layout) {
  store.dispatch('setLayout', layout)
}
/**
 *  创建实例
 */
new Vue({
  store,
  router,
  i18n,
  async created() {
    // 加载接口配置
    await this.$store.dispatch('ibps/api/load')
    // await this.$store.dispatch('ibps/dict/load')
  },
  render: h => h(App)
}).$mount('#app')
