/**
 * 和服务端进行交互
 *  它封装了全局 request拦截器、respone拦截器、统一的错误处理、统一做了超时，baseURL设置等
 * <pre>
 * 作者:hugh zhuang
 * 邮箱:3378340995@qq.com
 * 日期:2015-11-02-下午3:29:34
 * 版权:山东易企赢
 * </pre>
 */
import axios from 'axios'
import { Toast } from 'vant'
import store from '@/store'
import I18n from '@/utils/i18n' // Internationalization 国际化
import Utils from '@/utils/util'
import setting from '@/setting.js'

// 验权
import { getToken, updateToken } from '@/utils/auth'

import { showFullScreenLoading, tryHideFullScreenLoading } from './loading'

import { BASE_API, BASE_GATEWAY_API } from '@/api/baseUrl'
import { HEADER_TOKEN_KEY, HEADER_SYSTEM_ID, HEADER_TENANT_ID, MULTIPLE_DOMAIN, API_DOMAIN_NAMES } from '@/constant'

const TIMEOUT = setting.requestTimeout // 请求超时（timeout）时间

/**
 * 创建axios实例
 */
const service = axios.create({
  timeout: TIMEOUT, // request timeout
  withCredentials: true, //  跨域安全策略
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json;charset=utf-8'
  }
})

// 请求数
let requestCount = 0

/**
 * 请求(request)拦截器
 *
 *  get 请求  统一参数放在params里面 // 后台对应只有@RequestParam
 *      // `params` 是即将与请求一起发送的 URL 参数
 *     // 必须是一个无格式对象(plain object)或 URLSearchParams 对象
 *  post 请求 统一参数放在data里面    // json 格式 后台对应@RequestBody ,其他 后台对应@RequestParam
 *   === // `data` 是作为请求主体被发送的数据
 *     // 只适用于这些请求方法 'PUT', 'POST', 和 'PATCH'
 *    // 在没有设置 `transformRequest` 时，必须是以下类型之一：
 *    // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
 *     // - 浏览器专属：FormData, File, Blob
 *     // - Node 专属： Stream
 *   ==// post 请求 `params`  这个同get 但要注意  后台对应@RequestParam 请求的`Content-Type`是 application/x-www-form-urlencoded 用 qs.stringify 去构造数据
 */
service.interceptors.request.use(async config => {
  if (!config.baseURL) {
    config.baseURL = BASE_API(parseInt(requestCount / 5, 10))
    // 多域名处理
    if (MULTIPLE_DOMAIN) {
      requestCount >= ((API_DOMAIN_NAMES.length - 1) * 5) ? requestCount = 0 : requestCount++
    }
  }
  if (config.gateway) {
    config.baseURL = BASE_GATEWAY_API()
  }

  config.isLoading = config.isLoading !== undefined && config.isLoading !== null ? config.isLoading : true
  showFullScreenLoading(config.loading, config.isLoading)
  // 防止缓存
  // if (config.method.toUpperCase() === 'GET') {
  //   config.params = {
  //     ...config.params,
  //     _t: Date.parse(new Date()) / 1000
  //   }
  // }

  // 判断是否需要token
  if (setting.whiteRequestList.indexOf(config.url) !== -1) {
    return config
  }
  config.headers[HEADER_TOKEN_KEY] = getToken()
  // 系统ID
  if (store && store.getters.systemid) {
    config.headers[HEADER_SYSTEM_ID] = store.getters.systemid
  }
  // 租户ID
  if (store && store.getters.tenantid) {
    config.headers[HEADER_TENANT_ID] = store.getters.designTenantid ? store.getters.designTenantid : store.getters.tenantid
  }
  // 租户模式
  if (store && store.getters.isTenantOpen) {
    config.headers[HEADER_TENANT_ID] = store.getters.designTenantid ? store.getters.designTenantid : store.getters.tenantid
  }
  return config
}, error => {
  tryHideFullScreenLoading()
  Utils.log.error('request-error =>', error) // for debug
  // 处理异常
  handleErrorMessage(error ? error.response : null, error)
  Promise.reject(error)
})

/**
 * 响应(respone)拦截器
 */
service.interceptors.response.use(response => {
  console.log('response')
  tryHideFullScreenLoading()
  const config = response.config
  if (config.processDataType === 'none' || config.responseType === 'arraybuffer') {
    return response
  }
  const dataAxios = response.data

  const token = response.headers['token']

  if (token) {
    // 判断是否有刷新token
    updateToken({ access_token: token })
  }

  return dataAxios
},
// 异常处理
error => {
  tryHideFullScreenLoading()
  Utils.log.error('response-error =>', error) // for debug
  // 处理异常
  handleErrorMessage(error ? error.response : null, error)
  return Promise.reject(error)
}
)

/**
 * 显示错误消息
 * @param {*} errorMsg
 */
function showErrorMessage(errorMsg) {
  Toast({
    message: `${errorMsg}`,
    type: 'html',
    closeOnClick: true,
    duration: 5 * 1000
  })
}

/**
 *  处理错误消息
 * @param {*} response
 * @param {*} error
 */
function handleErrorMessage(response, error) {
  let errorState
  let errorMsg = ''
  let errorCause = ''
  if (response) {
    const config = response.config || {}
    if (response.data) {
      const dataAxios = response.data || {}
      errorState = dataAxios.code
      // errorMsg = dataAxios.msg
      errorCause = dataAxios.msg
      if (Utils.isNotEmpty(errorMsg)) { // 有错误消息
        errorMsg = Utils.isNotEmpty(errorCause) ? I18n.t('error.messageCause', {
          message: errorMsg,
          cause: errorCause
        }) : I18n.t('error.message', {
          message: errorMsg
        })
      } else if (Utils.isNotEmpty(errorCause)) { // 只有错误原因
        errorMsg = I18n.t('error.cause', {
          cause: errorCause
        })
      } else if (I18n.te('error.status.' + errorState)) { // 有错误编码
        errorMsg = I18n.t('error.status.' + errorState, {
          url: config.url
        })
      } else { // 未知状态
        errorMsg = errorMsg || I18n.t('error.unknown', {
          errorState
        })
      }
    } else {
      errorState = response.status
      errorMsg = response.statusText
      if (I18n.te('error.status.' + errorState)) { // 有错误编码
        errorMsg = I18n.t('error.status.' + errorState, {
          url: config.url
        })
      } else { // 未知状态
        errorMsg = errorMsg || I18n.t('error.unknown', {
          state: errorState
        })
      }
    }
  } else {
    errorMsg = I18n.t('error.network') // '服务器君开小差了，请稍后再试'
    errorState = error ? error.status : 500
    errorCause = errorMsg
  }
  showErrorMessage(errorMsg)
  const err = new Error(errorMsg)
  err.state = errorState
  err.cause = errorCause

  return err
}

export default service
