import { version } from '../package'

export default {
  title: 'XTBG平台',
  // 版本
  releases: {
    version: version,
    api: ''// 更新版本的api
  },
  // 全局key
  globalKey: 'xtbg-app-' + version,
  /**
     * @type {string | array} 'production' | ['production', 'development']
     * @description Need show err logs component.
     * The default is only used in the production env
     * If you want to also use it in dev, you can pass ['production', 'development']
     */
  errorLog: 'production',
  // 最长请求时间
  requestTimeout: 3000 * 10,
  // 白名单，匿名请求的URL
  whiteRequestList: [
    '/user/captcha',
    '/user/login',
    '/user/login/apply',
    '/authorize',
    '/authorize/apply',
    '/authentication',
    '/authentication/apply',
    '/user/open',
    '/user/register',
    '/user/open/tenant',
    '/user/reset/passwd',
    '/user/send/sms',
    '/saas/tenant/register'
  ]
}
