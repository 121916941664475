/**
 * 实现全局的请求 loading
 *  <pre>
 * 作者:hugh zhuang
 * 邮箱:3378340995@qq.com
 * 日期:2015-11-02-下午3:29:34
 * 版权:山东易企赢
 * </pre>
 */
import IbpsToast from '@/components/ibps-toast'
import I18n from '@/utils/i18n' // Internationalization 国际化

let needLoadingRequestCount = 0
let loadingInstance = null

/**
 * 加载
 * @param {*} config
 */
function startLoading(config = {}) {
  loadingInstance = IbpsToast.loading({
    mask: config.mask || false,
    forbidClick: config.forbidClick || true,
    duration: config.duration || 0,
    message: config.message || I18n.t('loading')
  })
}

/**
 * 清除loaing
 */
function closeLoading() {
  loadingInstance ? loadingInstance.clear() : loadingInstance = null
}

const tryCloseLoading = () => {
  if (needLoadingRequestCount === 0) {
    closeLoading()
  }
}

export function showFullScreenLoading(config, isLoading) {
  if (needLoadingRequestCount === 0 && isLoading) {
    startLoading(config)
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading() {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    setTimeout(() => {
      tryCloseLoading()
    }, 200)
  }
}
