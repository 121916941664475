/* Layout */
import Layout from '@/views/layout/layout'
import LayoutInfo from '@/views/layout/layout-info'
import LayoutDefault from '@/views/layout/layout-default'
// import Main from '@/views/layout/app-main'

// 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载
const _import = require('@/utils/util.import.' + process.env.NODE_ENV)

/**
 * 在主框架内显示
 */
const frameIn = [
  {
    path: '/',
    redirect: { name: 'dashboard' },
    component: Layout,
    children: [
      // 首页
      {
        path: 'dashboard',
        name: 'dashboard',
        meta: {
          title: '首页',
          auth: true
        },
        component: _import('/system/dashboard')
      },
      // 刷新页面 必须保留
      {
        path: 'refresh',
        name: 'refresh',
        hidden: true,
        component: _import('/system/function/refresh')
      },
      // 页面重定向 必须保留
      {
        path: 'redirect/:route*',
        name: 'redirect',
        hidden: true,
        component: _import('/system/function/redirect')
      }
    ]
  }
]

/**
 * 在主框架之外显示
 */
const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录'
    },
    component: _import('/system/login')
  },
  {
    path: '/iframe',
    name: 'iframe',
    meta: {
      title: 'iframe'
    },
    component: _import('/system/iframe')
  },
  {
    path: '/uuc',
    name: 'uucGateway',
    meta: {
      title: '办理',
      auth: false
    },
    component: _import('/system/login/uuc')
  }
]

/**
 * 错误页面
 */
const errorPage = [
  {
    path: '/404',
    name: 'error404',
    meta: {
      title: '404-页面不存在'
    },
    hidden: true,
    component: _import('/system/error/404')
  },
  {
    path: '/401',
    name: 'error401',
    meta: {
      title: '401- 未授权'
    },
    hidden: true,
    component: _import('/system/error/401')
  },
  {
    path: '/403',
    name: 'error403',
    meta: {
      title: '403-权限不足'
    },
    hidden: true,
    component: _import('/system/error/403')
  }
  // {
  //   path: '/nomenu',
  //   name: 'nomenu',
  //   meta: {
  //     title: '没有菜单资源'
  //   },
  //   hidden: true,
  //   component: _import('/system/error/nomenu')
  // }
]

// 自定义
const frameCustom = [
  {
    path: '/onlineForm/index',
    name: 'onlineForm',
    meta: {
      title: '办理',
      auth: false
    },
    component: _import('/approval/index')
  },
  {
    path: '/next-menu',
    redirect: 'index',
    component: LayoutInfo,
    children: [
      {
        path: 'index',
        name: 'next-menu',
        meta: { title: '更多', icon: 'dashboard' },
        component: _import('/system/dashboard/next-menu')
      }

    ]

  },
  // {
  //   path: '/message',
  //   component: Layout,
  //   redirect: 'index',
  //   children: [
  //     {
  //       path: 'index',
  //       name: 'message',
  //       meta: { title: '消息', icon: 'message' },
  //       component:_import('/message/index')
  //     }, {
  //       path: 'chat',
  //       name: 'chat',
  //       meta: { title: '聊天', icon: 'message' },
  //       component:_import('/message/index')
  //     }
  //   ]
  // },
  {
    path: '/notice',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'notice',
        meta: { title: '公告', icon: 'notice' },
        component: _import('/platform/notice/index')
      }
    ]
  },
  {
    path: '/contacts',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'contacts',
        meta: { title: '通讯录', icon: 'contacts' },
        component: _import('/platform/contacts/index')
      }
    ]
  },
  {
    path: '/my',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        name: 'my',
        meta: { title: '我的', icon: 'my' },
        component: _import('/platform/my/index')
      },
      {
        path: 'userInfo',
        name: 'userInfo',
        meta: { title: '个人信息', icon: 'my', isLeftBar: true, isShowBar: false },
        component: _import('/platform/my/user-info')
      },
      {
        path: 'changePassword',
        name: 'changePassword',
        meta: { title: '修改密码', icon: 'my', isLeftBar: true, isShowBar: false },
        component: _import('/platform/my/change-password')
      },
      // {
      //   path: 'setting',
      //   name: 'setting',
      //   meta: { title: '设置', icon: 'my', isLeftBar: true, isShowBar: false },
      //   component:_import('/platform/my/setting')
      // },
      {
        path: 'help',
        name: 'help',
        meta: { title: '帮助', icon: 'help', isLeftBar: true, isShowBar: false },
        component: _import('/platform/my/help')
      },
      {
        path: 'aboutUs',
        name: 'aboutUs',
        meta: { title: '关于我们', icon: 'my', isLeftBar: true, isShowBar: false },
        component: _import('/platform/my/about-us')

      }
    ]
  },
  {
    path: '/bpmn',
    redirect: 'pending',
    component: LayoutDefault,
    children: [
      // 兼容页面
      // http://localhost:9628/#/bpmn/middle/pending/index?taskId=5bcabe89-015e-11ec-9c1a-fa009ba70960
      {
        path: 'middle/pending/index',
        name: 'detail',
        meta: { title: '流程审批' },
        component: _import('/platform/bpmn/middle/pending/index')
      },
      // http://localhost:9628/#/bpmn/middle/form/index?defId=1404804933668061185
      {
        path: 'middle/form/index',
        name: 'procFormIndex',
        meta: { title: '流程查看' },
        component: _import('/platform/bpmn/middle/form/index')
      },
      // http://localhost:9628/#/bpmn/middle/handled/index?instId=1428544558421569538
      {
        path: 'middle/handled/index',
        name: 'detail',
        meta: { title: '流程详情' },
        component: _import('/platform/bpmn/middle/handled/index')
      },

      // 菜单路由

      {
        path: 'pending/index',
        name: 'pending',
        meta: { title: '待办事宜' },
        component: _import('/platform/bpmn/pending/index')
      },
      {
        path: 'pending-matter/index',
        name: 'pendingMatter',
        meta: { title: '待办事宜' },
        component: _import('/platform/bpmn/pending-matter/index')
      },

      {
        path: 'completed/index',
        name: 'completed',
        meta: { title: '办结事宜' },
        component: _import('/platform/bpmn/completed/index')
      },
      {
        path: 'handled/index',
        name: 'handled',
        meta: { title: '已办事宜' },
        component: _import('/platform/bpmn/handled/index')
      },
      {
        path: 'myRequest/index',
        name: 'myRequest',
        meta: { title: '我的申请' },
        component: _import('/platform/bpmn/my-request/index')
      },
      {
        path: 'newProcess/index',
        name: 'newProcess',
        meta: { title: '新建流程' },
        component: _import('/platform/bpmn/new-process/index')
      },
      {
        path: 'myDraft/index',
        name: 'myDraft',
        meta: { title: '我的草稿' },
        component: _import('/platform/bpmn/my-draft/index')
      },
      {
        path: 'myRevoke/index',
        name: 'myRevoke',
        meta: { title: '可撤销事务' },
        component: _import('/platform/bpmn/my-revoke/index')
      },
      {
        path: 'myCompleted/index',
        name: 'myCompleted',
        meta: { title: '我结束的流程' },
        component: _import('/platform/bpmn/my-completed/index')
      },

      {
        path: 'taskChange/index',
        name: 'taskChange',
        meta: { title: '我的转办代理' },
        component: _import('/platform/bpmn/task-change/index')
      },
      {
        path: 'taskChangeDetail',
        name: 'taskChangeDetail',
        meta: { title: '详细信息' },
        component: _import('/platform/bpmn/task-change/detail')
      }
    ]
  },
  {
    path: '/message/inner/receive',
    component: _import('/platform/message/inner/receive'),
    name: 'messageInnerReceive',
    meta: { title: '我的消息' }
  },
  {
    path: '/d/list/:id(\\w+)',
    component: _import('/platform/data/template-list'),
    name: 'dataTemplateList',
    meta: { title: '模版' }
  },
  // {
  //   path: '/d/form',
  //   component:_import('/platform/data/template-form'),
  //   name: 'dataTemplateform',
  //   meta: { title: '表单明细' }
  // },
  // {
  //   path: '/demo',
  //   component: LayoutInfo,
  //   redirect: 'form',
  //   children: [
  //     {
  //       path: 'form',
  //       name: 'demoForm',
  //       meta: { title: '表单Demo', icon: 'table' },
  //       component: _import('/demo/form')
  //     },
  //     {
  //       path: '1',
  //       name: 'demoForm1',
  //       meta: { title: '权限申请', icon: 'table' },
  //       component: _import('/oa/oaGemsPermission')
  //     }
  //     //     {
  //     //       path: 'toolbar',
  //     //       name: 'demoToolbar',
  //     //       meta: { title: '工具栏', icon: 'toolbar' },
  //     //       component:_import('/demo/toolbar')
  //     //     }
  //   ]
  // },

  { path: '*', redirect: '/404', hidden: true }
]

// 导出需要显示菜单的
export const frameInRoutes = frameIn

// 重新组织后导出
export default [
  ...frameIn,
  ...frameOut,
  ...errorPage,
  ...frameCustom
]
