// import { getUserInfo, openTenant } from '@/api/oauth2/user'
import { get } from '@/api/platform/system/dictData'

// import { getUserInfo, switchUser, exitSwitchUser, openUser, openTenant } from '@/api/oauth2/user'
import Utils from '@/utils/util'

export default {
  namespaced: true,
  state: {
    // 字典税局
    dictData: {}
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} context
     * @param {*} info info
     */
    set({ state, dispatch }, dictData) {
      return new Promise(async resolve => {
        // store 赋值
        state.dictData = dictData
        // 持久化
        await dispatch('ibps/db/set', {
          dbName: 'sys',
          path: 'dictData.info',
          value: dictData,
          user: false
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     */
    get({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.dictData = await dispatch('ibps/db/get', {
          dbName: 'sys',
          path: 'dictData.info',
          defaultValue: {},
          user: false
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} context
     */
    load({ state, dispatch }) {
      console.log('load')
      return new Promise(async(resolve, reject) => {
        get().then(async response => {
          if (!response) {
            reject(response)
          }

          // 设置当前
          await dispatch('set', response)
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    /**
     * 获取用户名
     * @param {*} param0
     */
    getAccount({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.account = await dispatch('ibps/db/get', {
          dbName: 'sys',
          path: 'account',
          defaultValue: '',
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    setAccount({
      state,
      dispatch
    }, account) {
      return new Promise(async resolve => {
        // store 赋值
        state.account = account
        // 持久化
        await dispatch('ibps/db/set', {
          dbName: 'sys',
          path: 'account',
          value: account,
          user: true
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * 设置注册用户账号
     * @param {*} param0
     * @param {*} tenantOpen
     */
    async setTenantOpen({
      state,
      dispatch
    }, tenantOpen) {
      // store 赋值
      state.tenantOpen = tenantOpen
      // 持久化
      await dispatch('ibps/db/set', {
        dbName: 'sys',
        path: 'register.tenant',
        value: state.tenantOpen,
        user: false
      }, { root: true })
    },
    /**
     *
     * @description 是否开启注册租户
     */
    async loadTenantOpen({
      state,
      dispatch
    }) {
      // 获取
      state.tenantOpen = await dispatch('ibps/db/get', {
        dbName: 'sys',
        path: 'register.tenant',
        value: state.tenantOpen,
        user: false
      }, { root: true })

      if (Utils.isEmpty(state.tenantOpen)) {
        const tenantOpen = false
        state.tenantOpen = tenantOpen
        await dispatch('setTenantOpen', tenantOpen)
        // await openTenant().then(async response => {
        //   const tenantOpen = response.data
        //   state.tenantOpen = tenantOpen
        //   await dispatch('setTenantOpen', tenantOpen)
        // }).catch(err => {
        //   console.error('loadRegisterTenantOpen:', err)
        //   dispatch('setTenantOpen', null)
        // })
      }
    },
    /**
     * @description 切换更新租户ID数据
     * @param {Object} state vuex state
     * @param {String} tenantid 租户ID
     */
    async setTenantids({ state, dispatch }, tenantid) {
      state.info.tenantId = tenantid
      // 持久化
      await dispatch('ibps/db/set', {
        dbName: 'sys',
        path: 'register.info.tenantId',
        value: state.info.tenantId,
        user: false
      }, { root: true })
    },
    setDesignTenantid({ state }, designTenantid) {
      state.designTenantid = designTenantid
    }
  }
}
