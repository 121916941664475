/**
 * 获得地址栏的某个参数
 * @param       {[type]} name [description]
 * @constructor
 */
export function getQueryString(name) {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

/**
 * 是否是微信
 */
export function validataWechat() {
  return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
