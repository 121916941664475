export default {
  // 用户相关
  userId: state => state.ibps.user.info && state.ibps.user.info.user ? state.ibps.user.info.user.id : '', // 用户ID
  userName: state => state.ibps.user.info && state.ibps.user.info.employee ? state.ibps.user.info.employee.name : '', // 姓名
  status: state => state.ibps.user.info && state.ibps.user.info.employee ? state.ibps.user.info.employee.status : '', // 用户状态
  isSuper: state => state.ibps.user.info && state.ibps.user.info.user ? state.ibps.user.info.user.isSuper === 'Y' : false, // 是否超级管理员
  account: state => state.ibps.user.info && state.ibps.user.info.user ? state.ibps.user.info.user.account : '', // 用户名

  regOpen: state => state.ibps.user.regOpen, // 注册状态

  userInfo: state => state.ibps.user.info, // 用户信息
  // =========== 租户
  tenant: state => state.ibps.user.info && state.ibps.user.info.mainTenant ? state.ibps.user.info.mainTenant || {} : {}, // 当前租户
  tenants: state => state.ibps.user.info && state.ibps.user.info.tenants ? state.ibps.user.info.tenants || [] : [], // 当前拥有的租户
  tenantid: state => state.ibps.user.info && state.ibps.user.info.tenantId ? state.ibps.user.info.tenantId || '' : '', // 租户id
  isTenantAdmin: state => state.ibps.user.info && state.ibps.user.info.isTenantAdmin ? state.ibps.user.info.isTenantAdmin || false : false, // 是否租户管理员

  isTenantOpen: state => state.ibps.user ? state.ibps.user.tenantOpen : false, // 是否租户模式
  // ===============
  hasDataChange: state => state.ibps.app.hasDataChange, // 是否有数据修改

  // ============ 表单
  form: state => state.ibps.form,

  single: state => state.ibps.api.single, // 单体应用
  baseApi: state => state.ibps.api.base, // baseAPI地址
  websocket: state => state.ibps.api.websocket, // websocket地址

  //
  dictData: state => state.ibps.dict.dictData // 数据字典

}
