/**
 * 操作帮助类
 * 处理增删改查、分页等相关操作
 * <pre>
 * 作者:hugh zhuang
 * 邮箱:3378340995@qq.com
 * 日期:2018-10-08-下午3:29:34
 * 版权:易企赢
 * </pre>
 */
import { Notify, Dialog } from 'vant'
import Utils from '@/utils/util'
import I18n from '@/utils/i18n'
import common from '@/constants/common.js'

const action = {
  descDialog: function({ title, message, className = 'ibps-dialog-desc', confirmButtonColor = '#3396FB', confirmButtonText = '知道了', overlay = true }) {
    Dialog.alert({
      title,
      message,
      confirmButtonColor,
      confirmButtonText,
      overlay,
      className,
      messageAlign: 'left'
    })
  },
  msg: function(message, options) {
    if (!options) {
      options = options || {}
    }
    options.message = message
    Notify(options)
  },
  /**
   * 操作警告提示
   */
  warning: function(message) {
    this.msg(message, {
      type: 'warning'
    })
  },
  /**
   * 操作成功提示
   */
  success: function(message) {
    this.msg(message, {
      type: 'success'
    })
  },
  error: function(message) {
    this.msg(message, {
      type: 'error'
    })
  },
  /**
   * 默认操作成功提示
   *  @param {*} message
   */
  successMessage: function(message = I18n.t('common.dialog.operateSuccess')) {
    this.success(message)
  },
  /**
   * 删除成功提示
   * @param {*} message
   */
  removeSuccessMessage: function(message = I18n.t('common.dialog.removeSuccess')) {
    this.success(message)
  },
  /**
 * 获取选择行的id
 * @param {*} rows
 * @param {*} pkKey
 */
  getSelectedIds: function(rows, pkKey = 'id') {
    const ids = []
    rows.forEach(row => {
      ids.push(row[pkKey])
    })
    return ids
  },
  /**
 * 选择记录
 * 只能选择一个记录
 * [一般用于编辑、明细等只选择一个记录]
 *
 * @param {*} selection
 */
  selectedRecord: function(selection) {
    return new Promise((resolve, reject) => {
      if (Utils.isEmpty(selection)) {
        this.warning(I18n.t('common.dialog.selectedRecords'))
        return reject(selection)
      }
      if (Array.isArray(selection) && selection.length > 1) {
        this.warning(I18n.t('common.dialog.multipleSelected'))
        return reject(selection)
      }
      if (Array.isArray(selection)) {
        resolve(selection[0])
      } else {
        resolve(selection)
      }
    })
  },
  /**
 * 选择多个记录
 * [一般用于删除等选择多个记录]
 *
 * @param {*} selection 选中的值
 * @param {*} isArray 是否数组格式返回 默认 false
 * @param {*} separator 分割符 默认 `,`
 */
  selectedMultiRecord: function(selection, isArray = false, separator = ',') {
    return new Promise((resolve, reject) => {
      if (Utils.isEmpty(selection)) {
        this.warning(I18n.t('common.dialog.selectedRecords'))
        return reject(selection)
      }

      if (!Array.isArray(selection)) {
        selection = selection.split(separator)
      }
      if (!isArray) { // 不是数组返回
        selection = selection.join(separator)
      }
      resolve(selection)
    })
  },
  /**
 * 删除记录
 * @param {*} rows
 * @param {*} pkKey
 */
  removeRecord: function(selection, confirmMsg = I18n.t('common.dialog.removeRecord'), isArray = false, separator = ',') {
    return new Promise((resolve, reject) => {
      if (Utils.isEmpty(selection)) {
        this.warning(I18n.t('common.dialog.selectedRecords'))
        return reject(selection)
      }

      if (!Array.isArray(selection)) {
        selection = selection.split(separator)
      }
      if (!isArray) { // 不是数组返回
        selection = selection.join(separator)
      }
      Dialog.confirm({
        title: I18n.t('common.dialog.title'),
        message: confirmMsg
      }).then(() => {
        resolve(selection)
      }).catch((err) => {
        reject(err)
      })
    })
  },

  /**
 * 保存成功提示
 */
  saveSuccessMessage: function(message = I18n.t('common.dialog.operateSuccess'), callback) {
    message = Utils.isNotEmpty(message) ? message : I18n.t('common.dialog.operateSuccess')

    // MessageBox.confirm(message,
    //   I18n.t('common.dialog.title'),
    //   {
    //     type: 'success',
    //     confirmButtonText: I18n.t('common.dialog.saveConfirmButtonText'),
    //     cancelButtonText: I18n.t('common.dialog.saveCancelButtonText'),
    //     closeOnClickModal: false,
    //     callback: (action) => {
    //       const flag = action !== 'confirm'
    //       callback(flag)
    //     }
    //   })
  },

  /**
 * 保存成功提示
 */
  saveSuccessAlert: function(message = I18n.t('common.dialog.operateSuccess'), callback) {
    // MessageBox.alert(message,
    //   I18n.t('common.dialog.title'),
    //   {
    //     type: 'success',
    //     closeOnClickModal: false,
    //     callback: (action) => {
    //       callback(action)
    //     }
    //   })
  },
  /**
 * 保存失败
 */
  saveErrorMessage: function(message, callback) {
    this.warning(message || I18n.t('common.dialog.saveError'))
    if (callback) { callback() }
  },
  initListData(vm, options = {}) {
    const resultTypeKey = options.resultTypeKey || 'resultType'
    const resultKey = options.resultKey || 'listData'
    const paginationKey = options.paginationKey || 'pagination'
    const pageKey = options.pageKey || 'page'

    const finishedKey = options.finishedKey || 'finished'

    vm[finishedKey] = false
    // 设置第一页，数据设置为空
    vm[paginationKey][pageKey] = 1
    vm[resultTypeKey] = 'init'
    vm[resultKey] = []
  },
  /**
 * 处理列表数据
 * @param vm 当前对象
 * @param data 后台返回的列表数据
 * @param options 参数
 *   dataResultKey 默认 dataResult
 *  pageResultKey 默认 pageResult
 *  resultKey 结果key 默认 listData
 *  pageKey 分页key 默认 pagination
 */
  handleListData: function(vm, data, options = {}) {
    console.log('handleListData',data)
    const dataResultKey = options.dataResultKey || 'list'
    const pageResultKey = options.pageResultKey || 'total'

    const resultKey = options.resultKey || 'listData'
    // const pageKey = options.pageKey || 'pagination'

    const loadingKey = options.loadingKey || 'loading'
    const refreshingKey = options.refreshingKey || 'refreshing'
    const finishedKey = options.finishedKey || 'finished'
    // const dataResultKey = options.dataResultKey || 'dataResult'
    // const pageResultKey = options.pageResultKey || 'pageResult'
    // const resultKey = options.resultKey || 'listData'
    const paginationKey = options.paginationKey || 'pagination'
    const pageKey = options.pageKey || 'page'
    const resultTypeKey = options.resultTypeKey || 'resultType'
    const resultMessageKey = options.resultMessageKey || 'resultMessage'

    const listData = data ? data[dataResultKey] || [] : []
    const pagination = data ? this.getPagination(vm[paginationKey], data[pageResultKey]) || {} : {}

    if (vm[refreshingKey]) {
      vm[resultKey] = []
      vm[refreshingKey] = false
    }

    if (Utils.isNotEmpty(listData)) {
      //
      pagination[pageKey] = pagination[pageKey] + 1
      vm[resultKey] = vm[resultKey].concat(listData)
    }
    vm[loadingKey] = false
    vm[finishedKey] = this.isFinished(pagination, vm[resultKey])

    vm[paginationKey] = pagination
    // 处理结果类型
    vm[resultTypeKey] = this.handleResultType(vm, options)
    vm[resultMessageKey] = null
  },
  /**
   * 兼容改造 返回page对象
   */
  getPagination: function(pagination, total) {
    return {
      page: pagination.page || common.PAGE,
      limit: pagination.limit || common.LIMIT,
      totalCount: total,
      totalPages: (total % (pagination.page || common.PAGE)) + 1
    }
  },
  handleResultType: function(vm, options) {
    const resultKey = options.resultKey || 'listData'
    const finishedKey = options.finishedKey || 'finished'
    const list = vm[resultKey]
    const finished = vm[finishedKey]
    if (list && list.length > 0) {
      if (finished) return 'finished'
      return
    }
    return 'empty'
  },
  isFinished({ totalCount }, listData) {
    // 异常终止
    if (Utils.isEmpty(listData)) {
      return true
    }
    return listData.length >= totalCount
  },
  handleErrorData: function(vm, e, options = {}) {
    const loadingKey = options.loadingKey || 'loading'
    const refreshingKey = options.refreshingKey || 'refreshing'
    const finishedKey = options.finishedKey || 'finished'
    const resultTypeKey = options.resultTypeKey || 'resultType'
    const resultMessageKey = options.resultMessageKey || 'resultMessage'
    vm[loadingKey] = false
    vm[refreshingKey] = false
    vm[finishedKey] = true
    vm[resultTypeKey] = e.state === 500 ? 'network' : 'error'
    vm[resultMessageKey] = e.message
  },
  /**
 *  设置分页设置
 */
  setPagination: function(pagination, defaultPagination) {
    pagination.page = defaultPagination ? defaultPagination.page || common.PAGE : common.PAGE
    pagination.limit = defaultPagination ? defaultPagination.limit || common.LIMIT : common.LIMIT
  },

  /**
 * 设置排序
 */
  setSorts: function(sorts, sort, defaultSorts = {}) {
    const defaultSortsData = function() {
      for (const key in defaultSorts) {
        sorts[key] = defaultSorts[key]
      }
    }
    // 清空属性
    for (const key in sorts) {
      delete sorts[key]
    }
    if (sort) {
      const { name, order } = sort
      if (name && order) {
        sorts[name] = order === 'ascending' ? 'ASC' : 'DESC'
      } else {
        defaultSortsData()
      }
    } else {
      defaultSortsData()
    }
  },
  /**
 * 格式分页数据
 * @param {} params 查询的参数
 * @param {} page 分页
 * @param {} sorts 排序
 */
  formatParams: function(inParams, pagination, sorts) {
    const results = { params: {}}
    if (inParams) {
      Object.keys(inParams).map((k) => {
        const value = inParams[k]
        if (Utils.isObject(value) && value['relation']) {
          return value
        } else {
          results.params[k] = value
        }
      })
    }

    if (pagination) {
      results.params = {
        'page': pagination.page || common.PAGE,
        'limit': pagination.limit || common.LIMIT,
        ...results.params
      }
    }
    if (sorts) {
      const orderField = Object.keys(sorts).map((k) => {
        return {
          'field': k,
          'order': sorts[k]
        }
      })
      results.params.orderField = orderField
    }

    return results
  },
  /**
   * 兼容方法
   * @param {*} params
   * @param {*} pagination
   * @param {*} sorts
   */
  formatParams2: function(params, pagination, sorts) {
    const results = {}
    if (params) {
      results.parameters = Object.keys(params).map((k) => {
        const value = params[k]
        if (Utils.isObject(value) && value['relation']) {
          return value
        } else {
          return {
            'key': k,
            'value': value
          }
        }
      })
    }

    if (pagination) {
      results.requestPage = {
        'pageNo': pagination.page || common.PAGE,
        'limit': pagination.limit || common.LIMIT
      }
      if (Utils.isNotEmpty(pagination.totalCount)) { // mock 数据时候要传
        results.requestPage['totalCount'] = pagination.totalCount
      }
    }
    if (sorts) {
      results.sorts = Object.keys(sorts).map((k) => {
        return {
          'field': k,
          'order': sorts[k]
        }
      })
    }

    return results
  },
  /**
 * 格式分页数据
 * @param {} params 查询的参数
 * @param {} page 分页
 * @param {} sorts 排序
 */
  formatParams3: function(params, pagination, sorts) {
    const results = {}
    if (params) {
      results.parameters = Object.keys(params).map((k) => {
        return {
          'key': k,
          'value': params[k] ? params[k] : ''
        }
      })
    }
    if (pagination) {
      const requestPage = {
        'pageNo': pagination.page || common.PAGE,
        'limit': pagination.limit || common.LIMIT
      }
      if (Utils.isNotEmpty(pagination.totalCount)) { // mock 数据时候要传
        requestPage['totalCount'] = pagination.totalCount
      }
      results.requestPage = requestPage
    }
    if (sorts) {
      results.sorts = Object.keys(sorts).map((k) => {
        return {
          'field': k,
          'order': sorts[k]
        }
      })
    }

    return results
  },
  /**
   * 兼容方法
   * @param {*} params
   * @param {*} pagination
   * @param {*} sorts
   */
  formatParams4: function(params, pagination, sorts) {
    const paramData = action.formatParams3(params, pagination, sorts)
    console.log('formatParams4', paramData)
    const parm = {}
    for (const parameter of paramData.parameters) {
      // for (const key in parameter) {
      //   console.log(key + '---' + parameter[key])
      // }
      parm[parameter['key']] = parameter['value']
    }
    if (paramData.requestPage) {
      parm['page'] = paramData.requestPage.pageNo
      parm['limit'] = paramData.requestPage.limit
    }
    return parm
  },
  /**
 * 下载
 */
  download: function(data, fileName, responseType = 'application/octet-stream') {
    const blob = data instanceof Blob ? data : new Blob([data], { type: responseType })
    if ('download' in document.createElement('a')) { // 非IE下载
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(link.href)
      document.body.removeChild(link)
    } else { // IE10+下载
      navigator.msSaveBlob(blob, fileName)
    }
  },

  /**
 * 导出文件
 */
  exportFile: function(data, fileName, responseType = 'application/octet-stream') {
    this.download(data, fileName, responseType)
  }

}

export default action
