<template>
  <transition name="fade-transform" mode="out-in">
    <!-- <keep-alive> -->
    <router-view class="router-view" />
    <!-- </keep-alive> -->
  </transition>
</template>
<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      return []
    }
  }
}
</script>

