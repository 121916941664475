import Vue from 'vue'
import Vuex from 'vuex'

import ibps from './modules/ibps'
import getters from './getters'
Vue.use(Vuex)
export default new Vuex.Store({
  modules: {
    ibps
  },
  getters,
  state: {
    headerLayout: true,
    layoutHash: {
      noneHeader: {
        key: 'headerLayout',
        value: false
      }
    }
  },
  actions: {
    setLayout: ({ state }, layout) => {
      const list = layout.split(',')
      for (const item of list) {
        const l = state.layoutHash[item]
        if (l) {
          state[l.key] = l.value
        }
      }
    }
  }
})
