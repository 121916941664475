<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import Storage from '@/utils/storage'
export default {
  name: 'App',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created() {
    this.i18nHandle(this.$i18n.locale)
  },
  methods: {
    i18nHandle(val, oldVal) {
      Storage.set('lang', val)
      document.querySelector('html').setAttribute('lang', val)
    }
  }
}
</script>
