import Vue from 'vue'
import VueRouter from 'vue-router'

// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import store from '@/store/index'
import util from '@/utils/util.js'
import i18n from '@/utils/i18n' // Internationalization 国际化
import { getQueryString, validataWechat } from '@/utils/url' // 验权
// 验权
import { getToken, getRefreshToken, updateToken, GetRequest } from '@/utils/auth'
import { IS_WECHAT_LOGIN } from '@/constant'
// 路由数据
import routes from './routes'

// fix vue-router NavigationDuplicated
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(err => err)
}
const VueRouterReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

// 导出路由 在 main.js 里使用
const router = new VueRouter({
  mode: 'history',
  routes
})

// 不重定向白名单
const whiteList = ['/login', '/register', '/forget', '/authredirect', '/uuc']
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async(to, from, next) => {
  // 进度条
  NProgress.start()
  // 这里将cookie里是否存有token作为验证是否登录的条件
  let hasToken = getToken()
  const { token } = GetRequest()
  if (token) {
    if (token !== hasToken) {
      updateToken({ access_token: token })
      hasToken = token
    }
  }
  if (hasToken && hasToken !== 'undefined') { // 从cookie 获取用户token
    // 登录 锁定 401没权限  403禁止访问
    if (to.name === 'locking' || to.name === 'login' || to.name === 'uucGateway' || to.name === 'error401' || to.name === 'error403' || to.name === 'nomenu') {
      next()
    } else {
      // 判断是否有帐号信息
      if (util.isEmpty(store.getters.userInfo)) {
        store.dispatch('ibps/user/load').then(res => { // 拉取用户信息,避免刷新用户丢失
          store.dispatch('ibps/dict/load')
          next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
        }).catch((e) => {
          NProgress.done() // 结束Progress
          // 前台登出
          store.dispatch('ibps/account/fedLogout').then(() => {
            next({ name: 'login' })
          }).catch((err) => {
            console.error(err)
          })
        })
      } else { // 动态改变权限,判断是否有菜单权限，或者刷新页面
        if (to.matched.length === 0) { // 不能匹配的路由
          return next({ path: '401', replace: true, query: { noGoBack: true }})
        } else {
          next()
        }
      }
      // end
    }
  } else {
    const isWechat = validataWechat()
    if (isWechat && IS_WECHAT_LOGIN && !from.name) { // 微信登陆
      const redirect = getQueryString('redirect')
      const code = getQueryString('code')
      store.dispatch('ibps/account/loginByWechat', {
        form: { 'code': code }
      }).then(() => {
        if (redirect && redirect !== 'login') {
          next({ path: redirect })
        } else {
          next()
        }
      }).catch((e) => {
        NProgress.done() // 结束Progress
        if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
          next()
        } else {
          next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
        }
      })
    } else {
    // 判断refresh tonken是否过期
      const refreshToken = getRefreshToken()
      if (util.isNotEmpty(refreshToken)) {
      // 刷新tonken
        await store.dispatch('ibps/account/refreshToken').then(() => {
          next()
        }).catch((err) => {
          console.error(err)
        })
      } else {
      // 在免登录白名单，直接进入
        if (whiteList.indexOf(to.path) !== -1) {
          next()
        } else {
        // 没有登录的时候跳转到登录界面
        // 携带上登陆成功之后需要跳转的页面完整路径
          next({
            name: 'login',
            query: {
              redirect: to.fullPath
            }
          })
          NProgress.done()
        }
      }
    }
  }
})

router.afterEach(to => {
  // 进度条
  NProgress.done()
  // 更改标题
  i18n.setTitle(to.meta.name || to.name, to.meta.title)
})

export default router
