
import storage from '@/utils/storage'
import { getLang } from '@/utils/auth'

// 首页
const DASHBOARD_NAME = 'dashboard'
const DASHBOARD_VIEW = {
  title: '首页',
  path: '/dashboard',
  name: DASHBOARD_NAME
}

const app = {
  namespaced: true,
  state: {
    language: getLang(), // 国际化

    isLoading: false,

    // 显示打开的页面的列表
    visitedViews: [DASHBOARD_VIEW],
    cachedViews: [],

    // 菜单相关
    activeTabbar: storage.get('activeTabbar') || '',
    // 标题
    title: '',

    messageCount: 0, // 消息数
    hasDataChange: false // 是否有数据改变
  },
  mutations: {
    UPDATE_LOADING_STATUS: (state, payload) => {
      state.isLoading = payload.isLoading
    },
    // =================其他====================================
    // 设置语言
    SET_LANGUAGE: (state, language) => {
      state.language = language
      storage.set('language', language)
    },
    // 设置标题
    SET_TITLE: (state, title) => {
      state.title = title
    },
    SET_DATA_CHANGE: (state, v) => {
      state.hasDataChange = v
    }
  },
  actions: {

    // ==============其他============
    // 设置语言
    setLanguage({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
    // 设置标题
    setTitle: ({ commit }, title) => {
      commit('SET_TITLE', title)
    },
    // 设置
    setDataChange: ({ commit }, v) => {
      commit('SET_DATA_CHANGE', v)
    },
    updateLoadingStatus: ({ commit }, v) => {
      commit('UPDATE_LOADING_STATUS', v)
    }

  }
}

export default app
