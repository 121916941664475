<template>
  <div style="height:100%;">
    <van-nav-bar
      :title="title"
      :left-text="$t('common.button.back')"
      left-arrow
      fixed
      @click-left="onClickLeft"
      @click-right="onClickRight"
    >
      <van-icon slot="right" name="wap-home-o" />
    </van-nav-bar>
    <app-main />
  </div>
</template>
<script>
import AppMain from './app-main'
import i18n from '@/utils/i18n'

export default {
  name: 'LayoutInfo',
  components: {
    AppMain
  },
  computed: {
    title() {
      const title = this.generateTitle(this.$route.name, this.$route.params.title || this.$store.getters.title || this.$route.meta.title)
      i18n.setTitle(title)
      return title
    }
  },
  methods: {
    generateTitle(name, title) { // generateTitle by vue-i18n
      return i18n.generateTitle(name, title)
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    onClickRight() {
      this.$router.push({ path: '/dashboard' })
    }
  }
}
</script>
